import { Script, ScriptStrategy } from 'gatsby';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { useCookieConsent } from '../../cookiebot';
import { isApp } from '../../utils/mobileAppUtils';

interface InContactContextType {
  isShown: boolean;
  show: () => void;
  hide: () => void;
  open: () => void;
}

const InContactContext = createContext<InContactContextType>({
  isShown: false,
  show: () => {},
  hide: () => {},
  open: () => {},
});

const InContactStyling = createGlobalStyle<{ $isShown: boolean }>`
  #cxone-guide-container {
    display: ${({ $isShown }) => !$isShown && 'none'};
  }
`;

export const InContactChat: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isShown, setIsShown] = useState(true);

  const { hasAcceptedNecessaryCookies } = useCookieConsent();

  const show = useCallback(() => {
    setIsShown(true);
  }, []);

  const hide = useCallback(() => {
    setIsShown(false);
  }, []);

  const observeDocument = useCallback(() => {
    const chatButton = document.querySelector('[data-entrypoint="chat"]');
    if (chatButton && chatButton instanceof HTMLButtonElement) chatButton.click();
    else console.error('Chat button not found');
  }, []);

  const contextValue = useMemo(
    () => ({
      isShown,
      show,
      hide,
      open: observeDocument,
    }),
    [hide, isShown, show, observeDocument]
  );

  return (
    <InContactContext.Provider value={contextValue}>
      <link rel="stylesheet" href="https://xweb.internalprod.com/kronan/kronan-styling.css" />
      {hasAcceptedNecessaryCookies && !isApp() && (
        <Script strategy={ScriptStrategy.postHydrate}>{`
        (function(n,u){
            window.CXoneDfo=n,
            window[n]=window[n]||function(){(window[n].q=window[n].q||[]).push(arguments)},window[n].u=u,
            e=document.createElement("script"),e.type="module",e.src=u+"?"+Math.round(Date.now()/1e3/3600),
            document.head.appendChild(e)
            })('cxone','https://web-modules-de-eu1.niceincontact.com/loader/1/loader.js');
            cxone('init', '1366');
            cxone('guide','init');
            cxone('chat','showSendButton');
      `}</Script>
      )}
      <InContactStyling $isShown={isShown} />
      {children}
    </InContactContext.Provider>
  );
};

export const useInContact = () => useContext(InContactContext);
