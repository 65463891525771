import { isDefined } from '@oriola-corporation/ui-components';
import { ProductCardProduct } from '../../../../../../components/product/productCard/types';
import { Product } from '../../../../../../components/types';

type TrackableFields =
  | 'title'
  | 'subTitle'
  | 'productNumber'
  | 'promotionPrice'
  | 'price'
  | 'brandName'
  | 'productCategory'
  | 'rating'
  | 'vatRate'
  | 'itemType'
  | 'atcCode'
  | 'marketingSensitive'
  | 'availability';

export type TrackableProduct = Pick<Product, TrackableFields>;

export const toTrackableProduct = (product: ProductCardProduct | Product): TrackableProduct => ({
  ...product,
  promotionPrice: product.promotionPrice || null,
  brandName: product.brandName || null,
  rating: product.rating || null,
  atcCode: product.atcCode || null,
  availability: product.availability || null,
});

const classifyItemType = (itemType: string) => {
  switch (itemType) {
    case 'HA':
    case 'HR':
      return 'TG';
    case 'RX':
      return 'A';
    case 'EX':
      return 'OTC';
    default:
      return 'N/A';
  }
};

const roundNumber = (num: number) => Math.round((num + Number.EPSILON) * 100) / 100;

export const formatFakeRxProduct = (
  priceExcludingVat?: number | null,
  availability?: string | null,
  discountRate = 0
) => {
  return {
    id: `A_product`,
    name: `A_product`,
    price: isDefined(priceExcludingVat) ? roundNumber(priceExcludingVat) : 'N/A',
    brand: `A_brand`,
    category: 'other_category',
    availability: availability ? availability : 'N/A',
    dimension2: roundNumber(discountRate * 100),
    dimension8: 'N/A',
    dimension9: 'A',
    dimension10: 'A',
    dimension11: 'N/A',
  };
};

export const formatProduct = (product: TrackableProduct, { price }: { price?: number } = {}) => {
  const priceForCustomer = price || product.promotionPrice || product.price;

  const discountRate = (product.price - priceForCustomer) / product.price;

  const priceExcludingVat = priceForCustomer / (1 + product.vatRate / 100);

  if (product.itemType === 'RX') {
    return formatFakeRxProduct(priceExcludingVat, product.availability?.status, discountRate);
  }

  return {
    id: product.productNumber,
    name: `${product.title} ${product.subTitle}`,
    price: roundNumber(priceExcludingVat) || 'N/A',
    brand: product.brandName || 'N/A',
    category: product.productCategory?.[0]?.name || 'N/A',
    availability: product.availability?.status || 'N/A',
    dimension2: roundNumber(discountRate * 100),
    dimension8: product.atcCode || 'N/A',
    dimension9: classifyItemType(product.itemType),
    dimension10: product.itemType,
    dimension11: product.rating ? product.rating.toFixed(1) : 'N/A',
  };
};

export type GAFormattedProduct = ReturnType<typeof formatProduct>;
