import { Cart } from '@apis/expedition';
import { useApi } from '@packages/api';
import { useAuth } from '@packages/auth';
import { isServer } from '@packages/gatsby-utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import React, { createContext, useCallback, useContext, useMemo, useRef } from 'react';
import { buildCartQueryKey } from '../../../../queries/prescriptionPages/cart/buildCartQueryKey';
import { PostMessage } from '../../../external/types';

type CartChangeListener = (cart: Cart) => void;

interface CartContextType {
  cart?: Cart;
  refresh: () => Promise<void>;
  isInitialized: boolean;
  isLoading: boolean;
  hasErrors: boolean;
  error?: unknown;
  onCartChange: (listener: CartChangeListener) => () => void;
  notifyCartChange: (cart: Cart) => void;
}

const noCartProvider = () => {
  throw new Error('CartContext: no provider found');
};

const CartContext = createContext<CartContextType>({
  isLoading: false,
  isInitialized: false,
  hasErrors: true,
  refresh: noCartProvider,
  onCartChange: noCartProvider,
  notifyCartChange: noCartProvider,
});

export const Provider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const {
    expedition: {
      cart: { getCart },
    },
  } = useApi();

  const queryClient = useQueryClient();

  const { isInitialized, isSignedIn } = useAuth();

  const cartQuery = useQuery({
    queryKey: buildCartQueryKey(),
    queryFn: getCart,
    enabled: isInitialized && isSignedIn,
  });

  const listeners = useRef<Set<CartChangeListener>>(new Set());

  const onCartChange = useCallback((listener: CartChangeListener) => {
    listeners.current.add(listener);

    return () => {
      listeners.current.delete(listener);
    };
  }, []);

  const notifyCartChange = useCallback(
    (cart: Cart) => {
      queryClient.setQueriesData({ queryKey: buildCartQueryKey() }, cart);

      const isApp = Cookies.get('kronan-app-only');

      if (!isServer() && window && isApp) {
        const message = { type: PostMessage.CART_CHANGE };
        window.postMessage(message);
      }

      listeners.current.forEach((listener) => listener(cart));
    },
    [queryClient]
  );

  const refetch = cartQuery.refetch;

  const contextValue: CartContextType = useMemo(() => {
    const isConfirmedSignedIn = isInitialized && isSignedIn;

    const isConfirmedSignedOut = isInitialized && !isSignedIn;
    return {
      isInitialized: isConfirmedSignedIn ? Boolean(cartQuery.data) : isConfirmedSignedOut,
      cart: isConfirmedSignedIn ? cartQuery.data : undefined,
      refresh: async () => {
        await refetch();
      },
      isLoading: cartQuery.fetchStatus === 'fetching',
      hasErrors: cartQuery.isError,
      error: cartQuery.error,
      onCartChange,
      notifyCartChange,
    };
  }, [
    isInitialized,
    isSignedIn,
    cartQuery.data,
    cartQuery.fetchStatus,
    cartQuery.isError,
    cartQuery.error,
    onCartChange,
    notifyCartChange,
    refetch,
  ]);

  return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>;
};

export const useCart = () => useContext(CartContext);
