import { useAuth } from '@packages/auth';
import { useCart as useOrdinaryCart } from '@packages/cart';
import { ErrorModal } from '@packages/ui';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useCart as useRxCart } from './contexts/Cart';

export const CartSyncer: React.FC = () => {
  const { t } = useTranslation('rx');

  const { isSignedIn, isInitialized } = useAuth();
  const { cart: rxCart, onCartChange: onRxCartChange } = useRxCart();
  const { cart, addPrescriptionBag, refresh } = useOrdinaryCart();

  const wasSignedIn = useRef(isSignedIn);

  useEffect(() => {
    if (isInitialized) {
      if (!isSignedIn && wasSignedIn.current) {
        refresh();
        wasSignedIn.current = false;
      }

      if (isSignedIn) {
        wasSignedIn.current = true;
      }
    }
  }, [isInitialized, isSignedIn, refresh]);

  useEffect(() => {
    return onRxCartChange(() => refresh());
  }, [onRxCartChange, refresh]);

  const addPrescriptionBagMutation = useMutation({ mutationFn: addPrescriptionBag });

  useEffect(() => {
    if (rxCart && cart) {
      const newPrescriptionBagId = rxCart.id;

      const hasOtherPrescriptionBagAttached =
        newPrescriptionBagId !== cart.newPrescriptionBag?.prescriptionBagId;
      const isReadyToUpdate =
        addPrescriptionBagMutation.isIdle || addPrescriptionBagMutation.isSuccess;

      if (hasOtherPrescriptionBagAttached && isReadyToUpdate) {
        addPrescriptionBagMutation.mutate(newPrescriptionBagId);
      }
    }
  }, [addPrescriptionBagMutation, cart, rxCart]);

  return (
    <ErrorModal
      id="cart-syncer-error-modal"
      hasErrors={addPrescriptionBagMutation.isError}
      title={t('cartSyncer.error.title')}
      description={t('cartSyncer.error.description')}
      closeLabel={t('cartSyncer.error.retry')}
      onClose={() => {
        window.location.reload();
      }}
    />
  );
};
