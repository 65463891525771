export interface Person {
  personalIdNumber: string;
  firstName: string;
  lastName: string;
  limits?: [{ reason: string; type: string }];
}

export interface Child extends Person {
  id: string;
}

export enum CustomerEventsEnum {
  NEW_PRESCRIPTION = 'NEW_PRESCRIPTION',
  EXPIRING_PRESCRIPTION = 'EXPIRING_PRESCRIPTION',
  EXPIRING_COST_PROTECTION_PERIOD = 'EXPIRING_COST_PROTECTION_PERIOD',
  NEW_POWER_OF_ATTORNEY_REQUEST = 'NEW_POWER_OF_ATTORNEY_REQUEST',
  EXPIRING_GIVEN_POWER_OF_ATTORNEY = 'EXPIRING_GIVEN_POWER_OF_ATTORNEY',
  EXPIRING_RECEIVED_POWER_OF_ATTORNEY = 'EXPIRING_RECEIVED_POWER_OF_ATTORNEY',
}

export interface CustomerEvents {
  recentEvents: Array<CustomerEventsEnum>;
}

export interface BasePowerOfAttorney {
  id: string;
  expirationDate: string;
  approveDate: string | null;
  approveDeadlineDate: string;
  status: 'ACTIVE' | 'INACTIVE' | 'PENDING';
}

export interface GivenPowerOfAttorney extends BasePowerOfAttorney {
  receiver: Person;
}

export interface ReceivedPowerOfAttorney extends BasePowerOfAttorney {
  giver: Person;
}

export interface MedicalProduct {
  name: string;
  description: string;
  formGroup: PrescriptionItemType;
  itemId: string;
}

interface Requester {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  profession: string;
  workplace?: {
    name: string;
    city: string;
  };
}

export interface Prescription {
  costProtected: boolean;
  dispenseInterval?: string | null;
  dosageInstructions: string;
  existsInCart: boolean;
  expirationDateTime: string;
  firstDispenseBeforeDateTime?: string;
  lastDispenseDateTime?: string;
  nextCostProtectedDispenseDateTime?: string;
  note?: string;
  numberOfDispensesAllowed: number;
  numberOfPackagesPerDispense: number;
  numberOfPackagesPrescribed?: number | null;
  packageContent?: string | null;
  packageLeafletLink?: string | null;
  packageType: string;
  prescriptionDateTime: string;
  prescriptionId: string;
  product: MedicalProduct;
  remainingAmount?: number | null;
  remainingDispenses: number;
  requester: Requester;
  substitutionNotAllowed: boolean;
  starterPack: boolean;
  status: {
    active: boolean;
    reason: 'ACTIVE' | 'NO_REMAINING_DISPENSES' | 'EXPIRED';
  };
  totalAmount?: number | null;
}

export interface Substitution {
  product: MedicalProduct;
  costProtected: boolean;
  packageLeafletLink?: string | null;
  packageContent: string;
}

export type AnimalSpeciesType = 'HORSE' | 'DOG' | 'CAT' | 'UNKNOWN';

export interface AnimalIdentity {
  species: string;
  speciesType: AnimalSpeciesType;
  identity: string;
  type: string;
}

export interface AnimalPrescription extends Prescription {
  animalData: AnimalIdentity;
  karensText: string | null;
  treatmentPurpose: string | null;
}

export interface Term {
  accepted: boolean;
  acceptDate: string | null;
}

export type CustomerDisabledReason = 'UNDERAGE';

export interface DisabledCustomerAction {
  enabled: false;
  reason: CustomerDisabledReason;
}

export interface EnabledCustomerAction {
  enabled: true;
  reason: null;
}

export type CustomerAction = DisabledCustomerAction | EnabledCustomerAction;

export interface Customer {
  firstName: string;
  lastName: string;
  personalIdNumber: string;
  terms: {
    personal: Term;
    animals: Term;
  };
  actions: {
    createPowerOfAttorney: CustomerAction;
    editGivenPowerOfAttorney: CustomerAction;
    receivePowerOfAttorney: CustomerAction;
    removeGivenPowerOfAttorney: CustomerAction;
  };
}

export type PrescriptionItemType =
  | 'drops'
  | 'effervescentTablet'
  | 'eyeDrops'
  | 'granulate'
  | 'inhaler'
  | 'injection'
  | 'lotion'
  | 'nasalSpray'
  | 'patch'
  | 'pills'
  | 'spray'
  | 'suppository'
  | 'tablets';

export interface CostProtectionPeriod {
  amountSpent: number;
  startDate: string;
  endDate: string;
}

export interface CostProtectionLevel {
  breakpoint: number;
  percentageDiscount: number;
}

export interface CostProtectionResponse {
  currentPeriod: CostProtectionPeriod;
  levels: CostProtectionLevel[];
  percentageDiscount: number;
  type: 'DEFAULT' | 'CHILD';
}

export interface Price {
  gross: number;
  net: number;
  reduction: {
    amount: number;
    type: 'COST_PROTECTED' | 'MINOR';
  } | null;
}

export interface SimplePrice {
  gross: number;
  net: number;
  reduction: number;
}

export type ProductBuyableStatus =
  | 'IN_STOCK'
  | 'ORDERABLE'
  | 'NOT_BUYABLE_ONLINE'
  | 'PHARMACIST_ASSISTANCE_NEEDED'
  | 'OVER_PRESCRIPTION_LIMIT';

export interface ProductBuyableInformation {
  buyable: boolean;
  reason: ProductBuyableStatus;
  reasonTitle: string;
  reasonDescription: string;
}

export interface ProductOption {
  buyableStatus: ProductBuyableInformation;
  price: Price | null;
  product: MedicalProduct;
  existsInCart: boolean;
  quantityInCart: number;
  packageLeafletLink?: string | null;
  costProtected: boolean;
}

export interface SubstitutionOption extends ProductOption {
  id: string;
  priority: number;
}

export interface CostProtectionStatus {
  covered: boolean;
  reason: 'NOT_COVERED' | 'COVERED' | 'BEFORE_NEXT_DISPENSE_DATE';
}

export interface ProductOptionsResponse {
  costProtection: CostProtectionStatus;
  quantity: number;
  defaultQuantity: number;
  maxQuantity: number;
  maxQuantityReason?: string;
  prescribed: ProductOption;
  substitutionRecommended: boolean;
  substitutions: SubstitutionOption[];
}

export interface Patient {
  name: string;
  personalIdNum: string;
  relation: {
    id: string;
    relation: 'CHILD' | 'POWER_OF_ATTORNEY';
  } | null;
}

export interface CartRow {
  deliveryParameters: string[];
  costProtection: CostProtectionStatus;
  prescriptionId: string;
  substitutionId?: string | null;
  product: MedicalProduct;
  selectedQuantity: number;
  maxQuantity: number;
  maxQuantityReason?: string;
  unitPrice: SimplePrice;
  cartItemId: string;
}

export interface AnimalCartRow extends CartRow {
  animalIdentity: AnimalIdentity;
}

export interface CartContent {
  patient: Patient | null;
  price: SimplePrice;
  rows: CartRow[];
}

export interface AnimalCartContent {
  price: SimplePrice;
  rows: AnimalCartRow[];
}

export const isAnimalCartContent = (
  cartContent: AnimalCartContent | CartContent
): cartContent is AnimalCartContent =>
  typeof (cartContent as { patient?: Patient | null }).patient === 'undefined';

export interface Cart {
  animalContent: AnimalCartContent;
  contents: CartContent[];
  id: string;
  numberOfItems: number;
  price: SimplePrice;
  vat: number;
  validationHash: string;
}
