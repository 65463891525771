import { ServiceMessage } from '@apis/web-notifier';
import { useApi } from '@packages/api';
import { buildAppQueryKey } from '@packages/react-query-utils';
import { useQuery } from '@tanstack/react-query';
import React, { createContext, useContext, useMemo } from 'react';

interface WebsiteMessagesContextType {
  messages: ServiceMessage[];
  isPending: boolean;
  hasErrors: boolean;
}

const WebsiteMessagesContext = createContext<WebsiteMessagesContextType>({
  messages: [],
  isPending: false,
  hasErrors: false,
});

export const useWebsiteMessages = () => useContext(WebsiteMessagesContext);

export const WebsiteMessagesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const {
    webNotifier: { getMessages },
  } = useApi();

  const { data, isPending, isError } = useQuery({
    queryKey: buildAppQueryKey('website-messages'),
    queryFn: getMessages,
  });

  const contextValue: WebsiteMessagesContextType = useMemo(
    () => ({
      isPending,
      hasErrors: isError,
      messages: data || [],
    }),
    [data, isError, isPending]
  );

  return (
    <WebsiteMessagesContext.Provider value={contextValue}>
      {children}
    </WebsiteMessagesContext.Provider>
  );
};
